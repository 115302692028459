<template>
  <div>
    <!-- <v-alert text dense color="info" border="left" style="font-size: 14px">
      {{ $t('labels.remove_handover_code') }}
    </v-alert> -->
    <v-row :dense="isMobile()">
      <v-col cols="12" md="2">
        <select-warehouse-model
          v-model="id_warehouse"
          :label="$t('labels.warehouse')"
          :single-line="false"
          @change="getDeliveryCompanies"
        ></select-warehouse-model>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="id_delivery_company"
          :items="deliveryCompanies"
          :label="$t('labels.delivery_company')"
          dense
          outlined
          hide-details
          @change="onDeliveryChange"
          class="c-input-small"
        >
          <template v-slot:selection="data">
            <div
              v-html="data.item.text"
              style="font-size: 12px; font-weight: 500"
            ></div>
          </template>
          <template v-slot:item="data">
            <div
              v-html="data.item.text"
              style="font-size: 12px; font-weight: 500"
            ></div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model.trim="code"
          :disabled="!id_delivery_company"
          ref="inputCodeRef"
          :label="placeholder"
          dense
          outlined
          hide-details
          @keyup.enter="scanTracking"
          class="c-input-small"
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          color="error"
          block
          @click="removeHandover('all')"
          :disabled="orders.length === 0"
        >
          {{ $t("labels.remove_all") }}
        </v-btn>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          color="warning"
          block
          @click="showConfirmRemove"
          :disabled="orders.length === 0"
        >
          {{ $t("labels.remove_order") }}
        </v-btn>
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          color="primary"
          block
          @click="confirmHandover"
          :disabled="isDisabledBtnSubmit"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-alert color="error" text dark class="mb-0">
          <div class="text-h6 mb-1">
            {{ $t("labels.cancelled") }}: {{ requestCancelledOrders.length }}
          </div>
          <div class="text-overline">
            <v-chip
              class="mr-1 mb-1"
              color="errorClear"
              v-for="order in requestCancelledOrders"
              :key="order.tracking_id"
              small
            >
              {{ hideString(order.tracking_id, 4) }}
              <span
                >&nbsp; {{ $t("labels.basket") }}: {{ order.basket_code }} -
                {{ $t("labels.position") }}: {{ findIndexBasket(order) }}
              </span>
            </v-chip>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12" md="6">
        <v-alert color="success" text dark class="mb-0">
          <div class="text-h6 mb-1">
            {{ $t("labels.sum") }}: {{ orders.length }}
            <span
              v-if="orders.length > limitOrders.length"
              class="text-overline"
            >
              ({{ limitOrders.length }} {{ $t("labels.nearest_order") }})
            </span>
          </div>
          <div class="text-overline">
            <v-chip
              class="mr-1 mb-1"
              :color="order.request_cancel ? 'error' : 'default'"
              v-for="order in limitOrders"
              :key="order.tracking_id"
              small
            >
              {{ order.tracking_id }}
              <small v-if="order.count_group > 0" class="black--text ml-1">
                ({{ order.count_group_scanned }}/{{ order.count_group }})
              </small>
            </v-chip>
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <HandoverHistory v-if="!isMobile()" class="mt-10" />

    <template v-else>
      <v-btn
        color="warning"
        outlined
        block
        class="mt-10"
        @click="showDialogHistory"
        >Lịch sử bàn giao</v-btn
      >
      <v-dialog v-model="dialogHandoverHistory" max-width="1200px">
        <v-card>
          <v-card-title class="text-h6">
            Lịch sử bàn giao
            <v-spacer></v-spacer>
            <v-btn small color="red darken-1" text @click="hideDialogHistory">
              {{ $t("labels.close") }}
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-1">
            <HandoverHistory v-if="dialogHandoverHistory" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog v-model="dialogConfirmRemove" persistent max-width="350px">
      <HandoverRemove
        v-if="dialogConfirmRemove"
        :codes="removeCodes"
        @cancel="cancelConfirmRemove"
        @confirm="confirmRemove"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  downloadExcelFile,
  formatDateTime,
  isMobile,
  hideString,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { BARCODE_REMOVE_HANDOVER } from "@/libs/const";
import _ from "lodash";
import moment from "moment";
import { generateRandomString } from "../../../libs/helpers";

export default {
  name: "Handover",
  components: {
    HandoverHistory: () => import("@/components/goods_issue/HandoverHistory"),
    HandoverRemove: () => import("@/components/goods_issue/HandoverRemove"),
  },
  data: () => ({
    isLoading: false,
    deliveryCompanies: [],
    id_warehouse: null,
    id_delivery_company: null,
    code: null,
    orders: [],
    dialogConfirmRemove: false,
    dialogHandoverHistory: false,
    basket: {},
    lastHandoverId: null,
  }),
  computed: {
    placeholder() {
      if (!this.basket || !this.basket.id) {
        return this.$t("labels.basket_code");
      }
      return this.$t("labels.basket_or_order_code");
    },
    removeCodes() {
      const codes = [];
      for (let i = 0; i < this.orders.length; i++) {
        const order = this.orders[i];
        codes.push(order.tracking_id);
        if (order.delivery_order_id) {
          codes.push(order.delivery_order_id.toUpperCase());
        }
      }
      return codes;
    },
    trackingIds() {
      return [...this.orders].map((order) => order.tracking_id);
    },
    groupByBasket() {
      return _.groupBy([...this.orders], "handover_id_basket");
    },
    requestCancelledOrders() {
      return [...this.orders].filter((order) => order.request_cancel === 1);
    },
    groupScanningOrders() {
      return [...this.orders].filter(
        (order) => order.count_group > order.count_group_scanned
      );
    },
    limitOrders() {
      if (this.orders.length > 3) {
        return [...this.orders].splice(0, 3);
      }
      return [...this.orders];
    },
    isDisabledBtnSubmit() {
      return (
        this.orders.length === 0 ||
        this.requestCancelledOrders.length > 0 ||
        this.groupScanningOrders.length > 0
      );
    },
  },
  methods: {
    downloadExcelFile,
    formatDateTime,
    isMobile,
    hideString,
    findIndexBasket(order) {
      const basketOrders = [
        ...this.groupByBasket[order.handover_id_basket],
      ].map((od) => {
        od.handover_scanned_at = moment(od.handover_scanned_at).unix();
        return od;
      });
      const basketOrdersX = _.orderBy(
        basketOrders,
        ["handover_scanned_at"],
        ["asc"]
      );
      const indexBasket = basketOrdersX.findIndex((ob) => ob.id === order.id);
      return indexBasket + 1;
    },
    async getDeliveryCompanies() {
      const { data } = await httpClient.post(
        "/common/v1/get-delivery-by-state-check-cancel",
        {
          id_warehouse: this.id_warehouse,
        }
      );
      this.deliveryCompanies = data.map((e) => {
        // let text = `<span style="display:inline-block;min-width:110px">${e.delivery_company}</span>`
        let text = `<div class="primary--text">${e.delivery_company}</div>`;
        // if (e.count_order || e.count_order_cancelled) {
        text += `   ${+e.count_order + +e.count_order_cancelled} = `;
        // }
        // if (e.count_order) {
        text += ` <span class="green--text">${
          e.count_order
        }</span> (<span class="grey--text font-weight-regular">${this.$t(
          "labels.handover_mandatory"
        )}</span>)`;
        // }
        // if (e.count_order_cancelled) {
        text += ` + <span class="error--text">${
          e.count_order_cancelled
        }</span> (<span class="grey--text font-weight-regular">${this.$t(
          "labels.cancelled"
        )}</span>)`;
        // }
        if (e.count_order || e.count_order_cancelled) {
          text += ` `;
        }
        return {
          value: e.id_delivery_company,
          text: text,
        };
      });
    },
    onDeliveryChange() {
      this.focusInputCode();
      this.getWaitingHandover();
    },
    async getWaitingHandover() {
      this.orders = [];
      const { data } = await httpClient.post(
        "/goods-issue-detail/v1/get-waiting-handover",
        {
          id_delivery_company: this.id_delivery_company,
        }
      );
      this.orders = [...data];
      this.basket = {};
    },
    async scanTracking() {
      if (!this.code) {
        return false;
      }
      if (this.code === BARCODE_REMOVE_HANDOVER) {
        // this.showConfirmRemove()
        document.getElementById("error_sound_player").play();
        this.code = null;
        return false;
      }

      const orderScanned = [];
      for (let i = 0; i < this.orders.length; i++) {
        const order = this.orders[i];
        orderScanned.push(order.tracking_id);
        if (order.delivery_order_id) {
          orderScanned.push(order.delivery_order_id.toUpperCase());
        }
        if (order.short_delivery_order_id) {
          orderScanned.push(order.short_delivery_order_id.toUpperCase());
        }
      }

      /* const checkIsset = this.orders.find((o) =>
        [
          o.tracking_id.toUpperCase(),
          (o.delivery_order_id && o.delivery_order_id.toUpperCase()) ||
            o.delivery_order_id,
        ].includes(this.code.toUpperCase())
      ); */
      const code = this.code.toUpperCase();
      if (orderScanned.includes(code)) {
        const checkIsset =
          this.orders.find(
            (o) =>
              o.tracking_id == code ||
              (o.delivery_order_id &&
                o.delivery_order_id.toUpperCase() == code) ||
              (o.short_delivery_order_id &&
                o.short_delivery_order_id.toUpperCase() == code)
          ) || {};
        this.code = null;
        this.$vToastify.error(
          this.$t("messages.order_handover_scanned", {
            time: this.formatDateTime(
              checkIsset.handover_scanned_at,
              "HH:mm:ss DD/MM/YYYY"
            ),
          })
        );
        document.getElementById(`DonDaQuet_player`).play();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/get-for-handover",
          {
            code: this.code,
            id_delivery_company: this.id_delivery_company,
            id_basket: this.basket.id || null,
          }
        );

        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();

        /*const checkTracking = this.orders.find(order => order.tracking_id === data.tracking_id)
        if (checkTracking) {
          this.$vToastify.error(this.$t('messages.order_scanned'))
          document.getElementById('error_sound_player').play()
          return false
        }*/

        if (data.basket) {
          this.basket = { ...data.basket };
          return false;
        }

        const order = data.order;
        if (!order) {
          this.$vToastify.error(this.$t("labels.order_code_not_found"));
          document.getElementById(`MaDonKhongTonTai_player`).play();
          return false;
        }
        if (order.count_group > 0) {
          this.orders = [...this.orders].filter((ord) => ord.id !== order.id);
        }
        this.orders.unshift({ ...order });
      } catch (e) {
        this.code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        switch (errMsg) {
          case "OrderNotFound":
            this.$vToastify.error(this.$t("labels.order_code_not_found"));
            document.getElementById(`MaDonKhongTonTai_player`).play();
            break;
          case "OrderNotStatus_0":
            this.$vToastify.error(this.$t("labels.status_invalid_0"));
            document.getElementById(`TrangThaiKhongHopLe_0_player`).play();
            break;
          case "OrderNotStatus_20":
            this.$vToastify.error(this.$t("labels.status_invalid_20"));
            document.getElementById(`TrangThaiKhongHopLe_20_player`).play();
            break;
          case "OrderNotStatus_35":
            this.$vToastify.error(this.$t("labels.status_invalid_35"));
            document.getElementById(`TrangThaiKhongHopLe_35_player`).play();
            break;
          case "OrderNotStatus_40":
            this.$vToastify.error(this.$t("labels.status_invalid_40"));
            document.getElementById(`TrangThaiKhongHopLe_40_player`).play();
            break;
          case "OrderNotStatus_60":
            this.$vToastify.error(this.$t("labels.status_invalid_60"));
            document.getElementById(`TrangThaiKhongHopLe_60_player`).play();
            break;
          case "OrderNotStatus_100":
            this.$vToastify.error(this.$t("labels.status_invalid_100"));
            document.getElementById(`TrangThaiKhongHopLe_100_player`).play();
            break;
          case "OrderNotStatus_160":
            this.$vToastify.error(this.$t("labels.status_invalid_160"));
            document.getElementById(`TrangThaiKhongHopLe_160_player`).play();
            break;
          case "OrderNotStatus_200":
            this.$vToastify.error(this.$t("labels.status_invalid_200"));
            document.getElementById(`TrangThaiKhongHopLe_200_player`).play();
            break;
          case "OrderNotStatus_220":
            this.$vToastify.error(this.$t("labels.status_invalid_220"));
            document.getElementById(`TrangThaiKhongHopLe_220_player`).play();
            break;
          case "OrderNotStatus_240":
            this.$vToastify.error(this.$t("labels.status_invalid_240"));
            document.getElementById(`TrangThaiKhongHopLe_240_player`).play();
            break;
          case "OrderErrorDelivery":
            this.$vToastify.error(this.$t("labels.order_wrong_delivery"));
            document.getElementById(`NhamDonRoi_player`).play();
            break;
          case "BasketErrorDelivery":
            this.$vToastify.error(this.$t("labels.basket_wrong_delivery"));
            document.getElementById(`NhamSotRoi_player`).play();
            break;
          case "OrderNotYou":
            this.$vToastify.error(this.$t("labels.no_permission_process"));
            document.getElementById(`KhongCoQuyenXuLy_player`).play();
            break;
          case "OrderRequestCancelled":
            this.$vToastify.error(
              this.$t("labels.order_cancelled_receive_return")
            );
            document.getElementById(`DonDaHuy_player`).play();
            break;
          default:
            this.$vToastify.error(errMsg);
            document.getElementById("error_sound_player").play();
        }
      }
    },

    focusInputCode() {
      setTimeout(() => {
        this.$refs.inputCodeRef.focus();
      }, 100);
    },

    async removeHandover(tracking) {
      if (
        tracking === "all" &&
        !confirm(this.$t("messages.confirm_clear_order_handover"))
      ) {
        return false;
      }
      let tracking_ids = [];
      if (tracking === "all") {
        tracking_ids = [...this.trackingIds];
      } else {
        tracking_ids = [tracking];
      }
      try {
        if (this.isLoading) {
          this.$vToastify.warning(this.$t("messages.loading"));
          return false;
        }
        this.isLoading = true;

        await httpClient.post(
          "/goods-issue-detail/v1/remove-handover-scanned",
          {
            tracking_ids,
          }
        );
        this.isLoading = false;
        if (tracking === "all") {
          this.orders = [];
        } else {
          const orders = this.orders.filter(
            (order) => order.tracking_id !== tracking
          );
          this.orders = [...orders];
        }
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async confirmHandover() {
      try {
        if (this.isLoading) {
          this.$vToastify.warning(this.$t("messages.loading"));
          return false;
        }
        this.isLoading = true;

        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/confirm-handover",
          {
            tracking_ids: this.trackingIds,
            id_delivery_company: this.id_delivery_company,
          }
        );

        this.lastHandoverId = data;
        this.isLoading = false;
        this.orders = [];
        document.getElementById(`ChoBienBanBanGiao_player`).play();
        this.$vToastify.success(this.$t("messages.wait_handover_record"));
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },

    showConfirmRemove() {
      this.dialogConfirmRemove = true;
    },

    cancelConfirmRemove() {
      this.dialogConfirmRemove = false;
      this.focusInputCode();
    },

    showDialogHistory() {
      this.dialogHandoverHistory = true;
    },

    hideDialogHistory() {
      this.dialogHandoverHistory = false;
      this.focusInputCode();
    },

    confirmRemove(confirm_code) {
      const order = [...this.orders].find((od) =>
        [
          od.tracking_id.toUpperCase(),
          (od.delivery_order_id && od.delivery_order_id.toUpperCase()) ||
            od.delivery_order_id,
        ].includes(confirm_code)
      );
      if (!order) {
        this.$vToastify.error(this.$t("labels.order_not_found"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.removeHandover(order.tracking_id);
    },

    listenSocket() {
      window.socket.on("order_request_cancelled", (data) => {
        if (
          data.handover_scanned_id_employee &&
          data.id_delivery_company &&
          data.tracking_id &&
          window.me &&
          this.id_delivery_company
        ) {
          if (
            data.handover_scanned_id_employee === window.me.identity_id &&
            data.id_delivery_company === this.id_delivery_company
          ) {
            this.$vToastify.warning(
              this.$t("messages.order_canceled", { tracking: data.tracking_id })
            );
            this.getWaitingHandover();
          }
        }
      });
      window.socket.on("order_handover_success", (data) => {
        if (data.id_handover === this.lastHandoverId) {
          this.downloadExcelFile(
            `${process.env.VUE_APP_API_V2_URL}/download/v1/handover-file`,
            {
              id: data.id_handover,
            },
            `A4_${moment().format("YYYYMMDD_HHmmss")}_${generateRandomString(
              6
            )}.xlsx`
          );
          this.lastHandoverId = null;
        }
      });
    },
  },
  mounted() {
    // this.getDeliveryCompanies();
    this.listenSocket();
  },
};
</script>

<style scoped></style>
